import React, { useContext, useEffect, useState } from "react";
import { CartContext } from "../../contexts";
import { addToCart, removeFromCart } from "../../contexts/actions";

import { CheckIcon, ShoppingBagIcon, MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/outline";

export default function QtyButton({ types, selectedVariant, name, isCart }) {
  const { cart, dispatchCart } = useContext(CartContext);
  const existingItem = cart.find((item) => {
    return item.type === types[selectedVariant];
  });

  const [qty, setQty] = useState(isCart ? existingItem.qty : 1);
  const [success, setSuccess] = useState(false);

  const handleChange = (direction) => {
    if (qty === 1 && direction === "down") {
      return null;
    }
    let newQty = direction === "up" ? qty + 1 : qty - 1;
    setQty(newQty);

    if (isCart) {
      if (direction === "up") {
        dispatchCart(addToCart(types[selectedVariant], 1, name));
      } else if (direction === "down") {
        dispatchCart(removeFromCart(types[selectedVariant], 1));
      }
    }
  };

  const handleCart = () => {
    setSuccess(true);
    dispatchCart(addToCart(selectedVariant, qty, name));
  };

  useEffect(() => {
    let timer;
    if (success) {
      timer = setTimeout(() => setSuccess(false), 1100);
    }
    return () => clearTimeout(timer);
  }, [success]);

  return (
    <div className="flex space-x-3 items-center">
      {isCart ? null : (
        <>
          {success ? (
            <div className="py-1 text-white text-sm rounded">
              <CheckIcon className="w-6 text-green-500 transition duration-500 ease-in-out transform" />
            </div>
          ) : (
            <button type="button" onClick={handleCart}>
              <ShoppingBagIcon className="lg:w-6 w-8 text-redmachete" />
            </button>
          )}
        </>
      )}
      <div className="flex space-x-1 items-center">
        <button
          type="button"
          onClick={() => handleChange("down")}
          className="font-header font-bold px-1 hover:text-gray-600 text-gray-400"
        >
          <MinusCircleIcon className="lg:w-4 w-8" />
        </button>
        <div className="text-lg font-bold">{qty}</div>
        <button
          type="button"
          onClick={() => handleChange("up")}
          className="font-header font-bold px-1 hover:text-gray-600 text-gray-400"
        >
          <PlusCircleIcon className="lg:w-4 w-8" />
        </button>
      </div>
    </div>
  );
}
