import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "./layout";
import ProductFrame from "../components/product-list/ProductFrameGrid";

export default function New() {
  const data = useStaticQuery(graphql`
    query GetNewKnives {
      allStrapiKnife(filter: { offer: { eq: "new" } }) {
        edges {
          node {
            name
            strapiId
            images {
              localFile {
                childImageSharp {
                  gatsbyImageData(height: 250)
                }
              }
            }
            price
            code
            cat {
              slug
              name
            }
          }
        }
      }
    }
  `);

  const newknives = data.allStrapiKnife.edges;

  return (
    <Layout>
      <div className="max-w-7xl mx-auto md:py-16">
        <div className="py-4 px-4 lg:px-0 font-header font-bold text-2xl text-graymachete">
          Новинки
        </div>
        <div className="grid md:grid-cols-4 grid-cols-1 md:gap-5 px-4 md:px-0 py-6">
          {newknives.map((it) => {
            return (
              <ProductFrame type={it.node} good={it} key={it.node.strapiId} />
            );
          })}
        </div>
      </div>
    </Layout>
  );
}
