import React from "react";
import { Link } from "gatsby";
import QtyButton from "./QtyButton";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";

export const toCurrency = (n) =>
  Intl.NumberFormat("Ru-ru", {
    style: "currency",
    currency: "RUB",
    minimumFractionDigits: 0,
  }).format(n);

export default function ProductFrame({ good, type }) {
  const img = type?.images[0]
    ? type.images[0].localFile.childImageSharp.gatsbyImageData
    : null;
  return (
    <div className="flex-col lg:shadow hover:shadow-md lg:border border-t border-b items-baseline bg-gray-50">
      <div className="block w-full bg-white">
        <Link to={`/product/${type.strapiId}`}>
          <div className="lg:flex-col flex justify-between">
            <div className="flex w-80 p-2 lg:p-0 lg:w-full lg:h-64 justify-center">
              {type?.images[0] ? (
                <GatsbyImage image={img} title={type.name} alt={type.name} />
              ) : (
                <div className="mx-auto relative self-center">
                  <StaticImage
                    src="../../images/noimg.jpg"
                    title={type.name}
                    alt={type.name}
                  />
                </div>
              )}
            </div>
            <div className="flex overflow-hidden p-2 self-center">
              <span className="lg:text-sm font-header text-center">
                {type.name}
              </span>
            </div>
          </div>
        </Link>
      </div>
      <div className="bg-gray-50 h-16">
        <div className="border-t flex justify-between items-center">
          {type.price === 0 ? (
            <div className="w-full font-header p-4 font-semibold text-gray-600 text-center">
              Нет в наличии
            </div>
          ) : (
            <>
              <div className="font-header font-bold text-xl p-4">
                {toCurrency(type.price)}
              </div>
              <div className="border-l p-4 flex">
                <QtyButton
                  name={type.name}
                  types={good.node}
                  selectedVariant={type}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
